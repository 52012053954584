export const API_URL = process.env.NEXT_PUBLIC_API_URL || "https://swap2.me/api";
export interface IChain {
    name: string;
    id: number;
    img: string;
    feeEstimation?: number;
}
export interface ICoin {
    logoURI: string;
    symbol: string;
    name: string;
    address: string;
    priceUSD?: string;
}
export interface IWallet {
    logoURI: string;
    id: string;
    name: string;
}

//Estimated by https://dune.com/msilb7/l2-and-l1-fee-comparison-benchmarks
export const CHAINS: IChain[] = [
    {
        name: 'Ethereum',
        id: 1,
        img: '/images/chains/eth.svg',
        feeEstimation: 2
    },
    {
        name: 'Optimism',
        id: 10,
        img: '/images/chains/optimism.svg',
        feeEstimation: 0.18
    },
    {
        name: 'BSC',
        id: 56,
        img: '/images/chains/bsc.svg',
        feeEstimation: 0.5
    },
    {
        name: 'Polygon',
        id: 137,
        img: '/images/chains/polygon.svg',
        feeEstimation: 0.07
    },
    {
        name: 'Arbitrum',
        id: 42161,
        img: '/images/chains/arbitrum.svg',
        feeEstimation: 0.13
    },
    {
        name: 'Avalanche',
        id: 43114,
        img: '/images/chains/avax.svg',
        feeEstimation: 0.08
    },
    {
        name:"Gnosis",
        id: 100,
        img: '/images/chains/gnosis.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"Fantom",
        id: 250,
        img: '/images/chains/fantom.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"OKX",
        id: 66,
        img: '/images/chains/okx.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"Moonriver",
        id: 1285,
        img: '/images/chains/moonriver.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"Moonbeam",
        id: 1284,
        img: '/images/chains/moonbeam.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"CELO",
        id: 42220,
        img: '/images/chains/celo.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"FUSE",
        id: 122,
        img: '/images/chains/fuse.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"Cronos",
        id: 25,
        img: '/images/chains/cronos.svg',
        feeEstimation: 1 //TODO
    },
    {
        name:"Boba",
        id: 288,
        img: '/images/chains/boba.png',
        feeEstimation: 1 //TODO
    },
    {
        name:"Velas",
        id: 106,
        img: '/images/chains/velas.png',
        feeEstimation: 1 //TODO
    },
    {
        name:"Aurora",
        id: 1313161554,
        img: '/images/chains/aurora.png',
        feeEstimation: 1 //TODO
    }
];
export const COINS = ['USDC', 'USDT', 'ETH', 'BSC'];

export enum UserActionRequired{
    Initial,
    None,
    Signature,
    Switch,
    Spending
}