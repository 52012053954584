import { stringify } from 'utils'
import { SensitiveParamFilter, SPFDefaultParams } from '@amaabca/sensitive-param-filter'
export const filter = new SensitiveParamFilter({
    params: SPFDefaultParams.concat([
        'password',
        'totp',
        'refresh_token',
        'auth_token',
        'access_token',
        'client_secret',
        'session',
        'secret',
        'authorization',
    ]),
    replacement: '***',
    whitelist: [],
})

export const warn = (...args) => {
    try {
        console.warn(stringify([...filter.filter(args)]))
    } catch (e) {
        console.error('[logger.warn] Error ', e)
    }
}

export const log = (...args) => {
    try {
        console.log(stringify([...filter.filter(args)]))
    } catch (e) {
        console.error('[logger.log] Error ', e)
    }
}
export const error = (...args) => {
    try {
        console.error(stringify([...filter.filter(args)]))
    } catch (e) {
        console.error('[logger.error] Error ', e)
    }
}
export const logger = { log, error, warn }
export default logger