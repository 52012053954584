import { Fragment, useEffect, useMemo, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { useRouter } from "next/router";
import { useDidUpdateEffect, useLogin } from "utils";
import { useWeb3Modal } from "@web3modal/react";
import {GiHamburgerMenu} from 'react-icons/gi'

const navigation = [];

export default function HomeNavigation({
    color = "white",
    className = "",
    displayDisconnectButton = false
}) {
    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    const [isConnectedWithHydration, setIsConnectedWithHydration] =
        useState(false);
    const { disconnect } = useDisconnect();
    const { login, signLoginMessage, logout, isLoggedIn, loginLoading } = useLogin();

    const {
        isOpen: connectModalIsOpen,
        open: openConnectModal,
        close
    } = useWeb3Modal();

    useEffect(() => {
        if (isConnected) {
            setIsConnectedWithHydration(true);
        } else {
            setIsConnectedWithHydration(false);
        }
    }, [isConnected]);
    
    useDidUpdateEffect(()=>{
        if(isConnected && !isLoggedIn && !loginLoading){
            (async()=>{
                const signResult = await signLoginMessage();
                if (signResult) {
                    const loginResult = await login(signResult);
                }
            })()
            
        }
        if(!isConnected && isLoggedIn){
            logout();
        }
    },[isConnected, loginLoading, isLoggedIn])

    return (
        <nav
            className={classNames([
                "relative mx-auto flex max-w-7xl items-center justify-between px-4",
                className
            ])}
            aria-label="Global"
        >
            <div className="flex flex-1 items-center">
                <div className="flex w-full items-center justify-between md:w-auto">
                    <a
                        href="/"
                        className={classNames([
                            "relative py-4 text-xl font-bold lg:text-3xl hover:opacity-80"
                        ])}
                    >
                        Swap2.me
                        {/* <img
                                className='h-8 w-auto sm:h-10'
                                src='https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500'
                                alt=''
                            /> */}
                            <div className="text-white bg-slate-800 rounded-xl px-3 py-1 text-xs absolute top-[5px] -right-[50px]">Alpha</div>
                    </a>
                </div>
                <div className="hidden space-x-10 md:ml-10 md:flex">
                    {navigation.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className="font-medium text-black hover:text-gray-300"
                        >
                            {item.name}
                        </a>
                    ))}
                </div>
            </div>
            <div className="hidden flex-row items-center justify-end md:flex">
                {displayDisconnectButton ? (
                    <>
                        {isConnectedWithHydration ? (
                            <>
                                <a
                                    href="/links"
                                    className="mr-8 text-black hover:underline"
                                >
                                    Manage links
                                </a>

                                <button
                                    onClick={() => {
                                        openConnectModal();
                                        // logout()
                                        // disconnect();
                                        // router.push(`/`, null, {
                                        //     shallow: true
                                        // });
                                    }}
                                    className="inline-flex items-center rounded-md px-4 py-2 text-sm font-medium text-white hover:opacity-80 bg-gradient-to-r from-red-400 via-fuschia-500 to-indigo-500"
                                >
                                    Wallet connected
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={() => {
                                        openConnectModal();
                                        // connect({
                                        //     connector: connectors.find(
                                        //         (x) => x.id === "metaMask"
                                        //     )
                                        // });
                                    }}
                                    className="inline-flex items-center rounded-md bg-gradient-to-r from-red-400 via-fuschia-500 to-indigo-500 px-4 py-2 text-sm font-medium text-white hover:opacity-80"
                                >
                                    Connect wallet
                                </button>
                            </>
                        )}
                    </>
                ) : null}
            </div>

            <Menu
                as="div"
                className="relative flex flex-row space-x-2 text-left md:hidden"
            >
                <div className="justify-content flex h-full flex-row items-center text-white">
                    <Menu.Button>
                        <GiHamburgerMenu size={30}/>
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="/links"
                                        className={classNames(
                                            active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                            "block cursor-pointer px-4 py-2 text-sm"
                                        )}
                                    >
                                        Manage links
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <p
                                        onClick={async () => {
                                            openConnectModal();
                                        }}
                                        className={classNames(
                                            active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                            "block cursor-pointer px-4 py-2 text-sm"
                                        )}
                                    >
                                        Manage wallet connection
                                    </p>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <p
                                        onClick={async () => {
                                            await disconnect();
                                        }}
                                        className={classNames(
                                            active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                            "block cursor-pointer px-4 py-2 text-sm"
                                        )}
                                    >
                                        Disconnect wallet
                                    </p>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </nav>
    );
}

