import logger, { filter } from 'utils/logger';
import Router from 'next/router';
import hotToast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { VscClose } from 'react-icons/vsc';
import { AiFillCloseCircle } from 'react-icons/ai';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { API_URL, CHAINS, COINS, IChain, ICoin, IWallet } from 'utils/constants';
import axios from 'axios';
import { useSignMessage } from 'wagmi';
import { useEffect, useRef } from 'react';
import useAxios from 'axios-hooks';

export const t = (key: string, options: any = {}) => {
    return key;
};

interface IToastProps {
    header?: string;
    text?: string;
    status?: 'success' | 'error' | 'info';
    duration?: number;
}

export const toast = ({
    header = null,
    text = null,
    status = 'success',
    duration = 2000
}: IToastProps) => {

    console.log("toast!")

    hotToast.custom(t => (
        <Transition
            show={true}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
        >
            <div className='w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden' style={{maxWidth:"600px"}}>
                <div className='p-4'>
                    <div className='flex items-start'>
                        <div className='flex-shrink-0 mr-2'>
                            {status === 'success' ? (
                                <AiOutlineCheckCircle
                                    color='#0c0'
                                    className='h-6 w-6 text-green-400'
                                    aria-hidden='true'
                                />
                            ) : null}
                            {status === 'error' ? (
                                <AiOutlineCheckCircle
                                    color='#f00'
                                    className='h-6 w-6 text-green-400'
                                    aria-hidden='true'
                                />
                            ) : null}
                        </div>
                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                            {header ? (
                                <p className='text-sm font-medium text-gray-900'>
                                    {header}
                                </p>
                            ) : null}
                            {text ? (
                                <p
                                    className={classNames(
                                        `text-sm text-gray-500`,
                                        {
                                            'mt-1': header
                                        }
                                    )}
                                >
                                    {text}
                                </p>
                            ) : null}
                        </div>
                        <div className='ml-4 flex-shrink-0 flex'>
                            <button
                                type='button'
                                className='bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                onClick={() => {
                                    hotToast.dismiss(t.id);
                                }}
                            >
                                <VscClose
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    ), {
        duration: duration
    });
};

const prune = (obj, depth = 3) => {
    if (Array.isArray(obj) && obj.length > 0) {
        return depth === 0 ? ['???'] : obj.map(e => prune(e, depth - 1));
    } else if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
        return depth === 0
            ? { '???': '' }
            : Object.keys(obj).reduce(
                  (acc, key) => ({ ...acc, [key]: prune(obj[key], depth - 1) }),
                  {}
              );
    } else {
        return obj;
    }
};

export const stringify = (obj, depth = 6, space = undefined) =>
    JSON.stringify(prune(obj, depth), null, space);

export const handleGraphqlErrors = errors => {
    try {
        logger.error('[handleGraphqlErrors] errors ', errors);
        logger.log('[handleGraphqlErrors] Router.route ', Router.route);
        logger.error(
            '[handleGraphqlErrors] GQL errors => ',
            errors.graphQLErrors
        );

        const errorMessages = [];
        try {
            if (errors.graphQLErrors) {
                for (const err of errors.graphQLErrors) {
                    logger.log('[handleGraphqlErrors] err ', err);
                    //Catching all 401 errors on all dash pages
                    //Can use errors?.operation?.operationName!== "GetUserProfile" is to suppress errors for some operations
                    if (
                        err?.extensions?.statusCode === 401 &&
                        Router.route !== '' &&
                        Router.route !== '/' &&
                        Router.route !== '/earn'
                    ) {
                        errorMessages.push(
                            t(
                                'You are logged out of system, please log in again'
                            )
                        );
                        Router.push('/signin');
                    } else {
                        if (
                            err?.extensions?.responseBody?.errors &&
                            Object.keys(err?.extensions?.responseBody?.errors)
                                .length
                        ) {
                            for (const key of Object.keys(
                                err?.extensions?.responseBody?.errors
                            )) {
                                errorMessages.push(
                                    err?.extensions?.responseBody?.errors[key]
                                );
                            }
                        }
                    }
                }
            }
        } catch (e) {
            logger.error('[handleGraphqlErrors] Handling errors failed ', e);
        }
        logger.log('[handleGraphqlErrors] errorMessages ', errorMessages);
        if (errorMessages && errorMessages.length) {
            toast({ status: 'error', text: errorMessages.join(',') });
        }
    } catch (e) {
        logger.log('handleGraphqlErrors failed ', e);
    }
};
export const d = (value)=>{
    if(!value){
        value = 0;
    }
    try{
        return new Decimal(value)
    }catch(e){
        return new Decimal(0);
    }
};
export const chainIdToChain = (chainId):IChain => {
    return CHAINS.find(c=>c.id === chainId);
}

export const getExplorerUrl = (chainId, txId) => {
    if(!chainId || !txId){
        return null;
    }
    switch (chainId) {
        case 10:
            return `https://optimistic.etherscan.io/tx/${txId}`
        case 56:
            return `https://bscscan.com/tx/${txId}`
        case 137:
            return `https://polygonscan.com/tx/${txId}`
        case 42161:
            return `https://arbiscan.io/tx/${txId}`
        case 250:
            return `https://ftmscan.com/tx/${txId}`
        case 128:
            return `https://www.hecoinfo.com/en-us/tx/${txId}`
        case 43114:
            return `https://snowtrace.io/tx/${txId}`
        case 1666600000:
            return `https://explorer.harmony.one/tx/${txId}`
        case 100:
            return `https://gnosisscan.io/tx/${txId}`
        case 1:
            return `https://etherscan.io/tx/${txId}`
        default:
            return null;
    }
}
export const isBaseToken = (chainId, tokenAddress)=>{
    tokenAddress = tokenAddress.toLowerCase();
    if(!chainId || !tokenAddress){
        return false;
    }

    if(tokenAddress==="0x0000000000000000000000000000000000000000"){
        return true;
    }
    return false;
};

export const useLogin = ()=>{

    const [{ data: meData, loading, error }, refetch] = useAxios(
        {
            method:"GET",
            url:`${API_URL}/me`,
            withCredentials: true
        },{
           manual: false,
        }
    );

    const {
        data,
        isError,
        isLoading: isSignMessageLoading,
        isSuccess,
        signMessage,
        signMessageAsync
    } = useSignMessage({
        message: 'Log in to swap2.me'
    });
    
    const signLoginMessage = async ()=>{
        let signResult 
        try{
            signResult = await signMessageAsync();
            console.log('signed ', signResult);
        }catch(e){
            toast({
                header: "Please sign the transaction",
                status:"error"
            })
            return null;
        }
        return signResult;
    }

    const login = async (signature)=>{
        const loginResult = await axios.post(`${API_URL}/login`, {
            signature
        },{
            withCredentials:true
        });
        if (loginResult?.data?.status == 'ok') {
            toast({
                header: "Logged in",
                status:"success"
            })
            return loginResult?.data
        }
        return loginResult?.data
    }

    const logout = async ()=>{
        const loginResult = await axios.post(`${API_URL}/logout`, {},{
            withCredentials:true
        });
        if (loginResult?.data?.status == 'ok') {
            return loginResult?.data
        }
        return loginResult?.data
    }

    return {
        isLoggedIn: meData?.status == "ok",
        checkLogin: refetch,
        loginLoading: loading,
        login,
        logout,
        signLoginMessage
    }
}

export function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);
  
    useEffect(() => {
      if (didMountRef.current) { 
        return fn();
      }
      didMountRef.current = true;
    }, inputs);
  }